import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/Layout"

const heroText =
  "REDΙ has a wide international network in both practice and academia that can ensure the successful delivery of resource-demanding projects."

export default function ToolsInfrastructure() {
  return (
    <Layout
      bodyClass="tools"
      heroTitle="Tools for Infrastructure"
      heroText={heroText}
      bcText="TOOLS FOR INFRASTRUCTURE"
    >
      <section className="about-whoweare">
        <Container>
          <Row id="infra-tools">
            <Col sm={12}>
              <h2 className="red-title">Tools for Infrastructure</h2>
              <div className="tools-box">
                <span className="red-subtitle">
                  1. Assessment of critical infrastructure
                </span>
                <div className="who-content">
                  <p>- User-defined parameterized bridge model</p>
                  <p>- Selection of input and output parameters of the model</p>
                  <p>
                    - Probabilistic treatment and derivation of fragility curves
                  </p>
                  <img src="images/infra2.jpg" alt="" />
                  <img src="images/infra1.jpg" alt="" />
                </div>
              </div>
              <div className="tools-box">
                <span className="red-subtitle">
                  2. Bridge safety under moving loads/masses
                </span>
                <div className="who-content">
                  <p>
                    Applying the principles of multibody dynamics, the inelastic
                    performance of the bridge and the co-simulation approach to
                    evaluate bridge safety for increased moving loads.
                  </p>
                  <img src="images/infra3a.jpg" alt="" />
                  <img src="images/infra3.jpg" alt="" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}
